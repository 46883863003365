import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";
import DateRangePicker from "react-daterange-picker";
import BarChart from "./Barchart";
import LineChart from "./Linechart";
import {getSourceStatsByDateRange, getYearlySourceStats, subscribeToTrial, sourceSelected, measurementSelected} from "../actions";
import {Col, Grid, Row} from "react-flexbox-grid";
import * as selectors from "../selectors";
import {ALL_DASHBOARD_MEASUREMENTS} from "../constants";
import SwitchSelector from "../../core/Components/SwitchSelector/SwitchSelector";
import "react-daterange-picker/dist/css/react-calendar.css";
import "./dashboard.css";
import * as authSelectors from "../../Auth/selectors";

class Dashboard extends Component {
    onDateRangeChange = dates => {
        this.setState({dates}, () => {
            this.props.getSourceStatsByDateRange(dates.start, dates.end);
        });
    };
    onSourceClick = (node) => {
        this.props.sourceSelected(node.data.source);
    };

    constructor(props) {
        super(props);
        this.state = {
            dates: moment().range(moment().startOf("day").add(-7, "days"), moment().startOf("day"))
        };
    }

    componentWillMount() {
        const {start, end} = this.state.dates;
        this.props.getSourceStatsByDateRange(start, end);
        this.props.getYearlySourceStats();
    }

    measurementChanged = selectedOption => {
        this.props.measurementSelected(selectedOption);
    };

    subscribeToTrial = () => {
        this.props.subscribeToTrial();
    };

    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps.trialCreated){
            //document.location.replace("https://dash.prodashes.com?token=" + nextProps.paramsToken);
        }
    }

    render() {
        const {soldBySource, soldBySubSource, dailySourceStatsLoading, dashboardMeasurement, soldBySourceYearly, soldBySourceMonthly, selectedSource} = this.props;
        return (
            <Grid fluid className="dashboardContainer">
                <Row className="pro-link">
                    <p>Pro Version available! <button onClick={this.subscribeToTrial}>Upgrade to Pro</button></p>
                </Row>
                <Row className="dashboardHeader">
                    <Col>
                        <SwitchSelector
                            value={dashboardMeasurement}
                            onChange={this.measurementChanged}
                            options={ALL_DASHBOARD_MEASUREMENTS}
                        />
                    </Col>
                    <Col className="dashboardHeaderDate">
                        <DateRangePicker
                            onSelect={this.onDateRangeChange}
                            value={this.state.dates}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <BarChart indexBy={"source"}
                                  description={"* Press on any Source to see all SubSources!"}
                                  data={soldBySource}
                                  loading={dailySourceStatsLoading}
                                  onClick={this.onSourceClick}
                                  title="Sold by source"
                                  textDisplayKey="source"
                        />
                    </Col>
                    <Col lg={6}>
                        <BarChart indexBy={"subsource"}
                                  data={soldBySubSource}
                                  loading={dailySourceStatsLoading}
                                  title={selectedSource ? `Sold by sub source: ${selectedSource}` : "Select Source to view this chart!"}
                                  textDisplayKey="subsource"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <LineChart data={soldBySourceYearly} title="Sold by source yearly"
                                   textDisplayKey="id"
                                   legendLeftText={dashboardMeasurement.label}
                        />
                    </Col>
                    <Col lg={6}>
                        <LineChart data={soldBySourceMonthly} title="Sold by source monthly"
                                   textDisplayKey="id"
                                   legendLeftText={dashboardMeasurement.label}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <div style={{width: "100%", paddingLeft: 50}}>
                            <h2>Check out what PRO version includes! </h2>
                            <b><p>Drag'n'Drop and Resize feature! With saving your configuration!</p></b>
                            <br/>
                            <b><p>- Orders dashboard</p></b>
                            <p>1. Orders count received for each Source by selected date range.</p>
                            <p>2. Orders count received for All Subsources and selected Source by selected date
                                range.</p>
                            <p>3. Orders count monthly this year for each source</p>
                            <p>4. Orders count yearly for the whole hisory.</p>
                            <p>5. Orders daily for each source for selected date range</p>

                            <b><p>- Sales dashboard</p></b>
                            <p>1. Orders revenue daily for each source for selected date range</p>
                            <p>2. Orders revenue received for each Source by selected date range.</p>
                            <p>3. Orders revenue received for All Subsources and selected Source by selected date
                                range.</p>
                            <p>4. Orders revenue monthly this year for each source</p>
                            <p>5. Orders revenue count yearly for the whole hisory.</p>

                            <b><p>- User Performance dashboard</p></b>
                            <p>1. Count of processed orders monthly within last 6 months.</p>
                            <p>2. Count of processed orders daily within last 14 days.</p>
                            <p>3. Count of processed orders hourly within last 24 hours.</p>

                            <b><p>- Inventory dashboard</p></b>
                            <p>1. Top 10 SKUs sold (processed) within last 30 days - Count and orders count</p>
                            <p>2. Top 10 SKUs sold (processed) within last 30 days - Revenue and Profit</p>
                            <p>3. Top 10 SKUs sold (processed) within last year - Count and orders count</p>
                            <p>4. Top 10 SKUs sold (processed) within last year - Revenue and Profit</p>
                            <p>5. Top 10 SKUs sold (processed) for selected date range - Sold (procesed) daily</p>
                        </div>
                    </Col>
                    <Col lg={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <iframe width="800" height="600" src="https://www.youtube.com/embed/M56edfC3xUk" frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                    </Col>
                </Row>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    const soldBySource = selectors.getSoldBySource(state);
    const soldBySubSource = selectors.getSoldBySubSource(state);
    const dailySourceStatsLoading = selectors.getDailySourceStatsLoading(state);
    const dashboardMeasurement = selectors.getDashboardMeasurement(state);
    const soldBySourceYearly = selectors.getSoldBySourceYearly(state);
    const soldBySourceMonthly = selectors.getSoldBySourceMonthly(state);
    const trialCreated = selectors.getTrial(state);
    const paramsToken = authSelectors.getParamsToken(state);
    return {
        soldBySource,
        soldBySubSource,
        dailySourceStatsLoading,
        dashboardMeasurement,
        soldBySourceYearly,
        soldBySourceMonthly,
        trialCreated,
        selectedSource: selectors.getSelectedSource(state),
        paramsToken
    };
}

export default connect(mapStateToProps, {
    getSourceStatsByDateRange,
    getYearlySourceStats,
    sourceSelected,
    measurementSelected,
    subscribeToTrial
})(Dashboard);
