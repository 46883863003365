import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { NAME, ALL_DASHBOARD_MEASUREMENTS } from './constants';
import * as actionTypes from './actionTypes';

const initialState = {
    dailySourceStats: [],
    dailySourceStatsLoading: false,
    yearlySourceStats: [],
    yearlySourceStatsLoading: false,
    selectedSource: null,
    dashboardMeasurement: ALL_DASHBOARD_MEASUREMENTS[0],
    trialCreated: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SOURCE_STATS_BE_DATE_RANGE.REQUEST:
            return {
                ...state,
                dailySourceStatsLoading: true
            };
        case actionTypes.GET_SOURCE_STATS_BE_DATE_RANGE.SUCCESS:
            return {
                ...state,
                dailySourceStats: action.payload,
                dailySourceStatsLoading: false,
                selectedSource: action.payload[0].source
            };
        case actionTypes.GET_SOURCE_STATS_BE_DATE_RANGE.ERROR:
            return {
                ...state,
                dailySourceStatsLoading: false,
                selectedSource: null
            };
        case actionTypes.GET_YEARLY_SOURCE_STATS.REQUEST:
            return {
                ...state,
                yearlySourceStatsLoading: true
            };
        case actionTypes.GET_YEARLY_SOURCE_STATS.SUCCESS:
            return {
                ...state,
                yearlySourceStats: action.payload,
                yearlySourceStatsLoading: false
            };
        case actionTypes.GET_YEARLY_SOURCE_STATS.ERROR:
            return {
                ...state,
                yearlySourceStatsLoading: false
            };
        case actionTypes.SOURCE_CHANGED.SUCCESS:
            return {
                ...state,
                selectedSource: action.payload
            };

        case actionTypes.MEASUREMENT_CHANGED.SUCCESS:
            return {
                ...state,
                dashboardMeasurement: action.payload
            };

        case actionTypes.TRIAL_CREATED.SUCCESS:{
            return {
                ...state,
                trialCreated: true
            };
        }
        default:
            return state;
    }
};

const persistConfig = {
    key: NAME,
    storage,
    whitelist: []
};

export default persistReducer(persistConfig, reducer);