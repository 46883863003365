import * as actionTypes from './actionTypes';
import * as api from './api';

export const getSourceStatsByDateRange = (start, end) => async dispatch => {
    dispatch({ type: actionTypes.GET_SOURCE_STATS_BE_DATE_RANGE.REQUEST });
    return api.getSourceStatsByDateRange(start, end)
        .then(response => {
            dispatch({
                type: actionTypes.GET_SOURCE_STATS_BE_DATE_RANGE.SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            dispatch({ type: actionTypes.GET_SOURCE_STATS_BE_DATE_RANGE.ERROR });
        });
};

export const getYearlySourceStats = (data) => async dispatch => {
    dispatch({ type: actionTypes.GET_YEARLY_SOURCE_STATS.REQUEST });
    return api.getYearlySourcesStats(data)
        .then(response => {
            dispatch({
                type: actionTypes.GET_YEARLY_SOURCE_STATS.SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            dispatch({ type: actionTypes.GET_YEARLY_SOURCE_STATS.ERROR });
        });
};

export const subscribeToTrial = () => async dispatch => {
    return api.subscribeToTrial()
        .then(response => {
            if(response){
                dispatch({ type: actionTypes.TRIAL_CREATED.SUCCESS });
                alert('Please reload this page to see PRO Version!');
            }else{
                alert('Something went wrong in LW side :( try upgrade manually from App Store')
            }
        })
        .catch(error => {
            alert('Something went wrong in LW side :( try upgrade manually from App Store')
        });
};

export const sourceSelected = (data) => async dispatch => {
    dispatch({ type: actionTypes.SOURCE_CHANGED.SUCCESS, payload: data });
};

export const measurementSelected = (data) => async dispatch => {
    dispatch({ type: actionTypes.MEASUREMENT_CHANGED.SUCCESS, payload: data });
};
