import React from 'react';
import _ from 'lodash';
import './switchSelector.css';

class SwitchSelector extends React.PureComponent {
    get isSecondSelected() {
        return this.props.value.value === this.props.options[1].value;
    }

    renderItem = item => {
        return (
            <div
                key={item.value}
                onClick={() => this.props.onChange(item)}
                className={`switchSelectorItem ${item.value === this.props.value.value ? 'switchSelectorItem--selected' : ''}`}>
                <span>{item.label}</span>
            </div>
        )
    };

    render() {
        const { options } = this.props;
        return (
            <div className="switchSelector">
                {_.map(options, this.renderItem)}
                <div className={`switchSelectorBackground ${this.isSecondSelected ? 'switchSelectorBackgroundSecond' : ''}`} />
            </div>
        );
    }
}

export default SwitchSelector;