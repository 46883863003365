import axios from 'axios';

export const endpoints = {
    sourceStatsByDateRange: (start, end) => `linnworksDashboard/getBySourceAndSubsource/${start.format('MM-DD-YYYY')}/${end.format('MM-DD-YYYY')}`,
    yearlySourcesStats: () => 'linnworksDashboard/getYearlySourcesStats',
    subscribeToTrialUrl: () => 'linnworksDashboard/subscribeToTrial'
};

export const getSourceStatsByDateRange = (start, end) => {
    return axios.get(endpoints.sourceStatsByDateRange(start, end));
};

export const getYearlySourcesStats = params => {
    return axios.get(endpoints.yearlySourcesStats(), { ...params });
};

export const subscribeToTrial = params => {
    return axios.get(endpoints.subscribeToTrialUrl(), { ...params });
};
