import _ from 'lodash';
import moment from 'moment';
import {NAME} from './constants';

export const getSoldBySource = state => {
    const measurement = getDashboardMeasurement(state);
    const dailyStatsBySource = _.get(state[NAME], 'dailySourceStats');
    const selected = dailyStatsBySource.map(item => {
        if (measurement.value === 'ordersCount') {
            return {
                source: item.source,
                processed: item.processed,
                opened: item.received - item.processed,
                total: item.received
            };
        } else {
            return {
                source: item.source,
                processed: Number((Math.round(item.processedCharge * 100) / 100).toFixed(2)),
                opened: Number((Math.round((item.totalCharge - item.processedCharge) * 100) / 100).toFixed(2)),
                total: Number((Math.round(item.totalCharge * 100) / 100).toFixed(2))
            };
        }
    });
    const grouped = [];
    selected.forEach(item => {
        const index = grouped.findIndex(el => el.source === item.source);
        if (index >= 0) {
            grouped[index].processed = Number((Math.round((grouped[index].processed + item.processed) * 100) / 100).toFixed(2));
            grouped[index].opened = Number((Math.round((grouped[index].opened + item.opened) * 100) / 100).toFixed(2));
            grouped[index].total = Number((Math.round((grouped[index].total + item.total) * 100) / 100).toFixed(2));
        } else {
            grouped.push(item);
        }
    });
    return grouped;
};
export const getSoldBySubSource = (state) => {
    const measurement = getDashboardMeasurement(state);
    const dailyStatsBySource = _.get(state[NAME], 'dailySourceStats');
    const selected = dailyStatsBySource.filter(item => item.source === state[NAME].selectedSource).map(item => {
        if (measurement.value === 'ordersCount') {
            return {
                subsource: item.subsource,
                processed: item.processed,
                opened: item.received - item.processed,
                total: item.received
            };
        } else {
            return {
                subsource: item.subsource,
                processed: Number((Math.round(item.processedCharge * 100) / 100).toFixed(2)),
                opened: Number((Math.round((item.totalCharge - item.processedCharge) * 100) / 100).toFixed(2)),
                total: Number((Math.round(item.totalCharge * 100) / 100).toFixed(2))
            };
        }
    });
    const grouped = [];
    selected.forEach(item => {
        const index = grouped.findIndex(el => el.subsource === item.subsource);
        if (index >= 0) {
            grouped[index].processed = Number((Math.round((grouped[index].processed + item.processed) * 100) / 100).toFixed(2));
            grouped[index].opened = Number((Math.round((grouped[index].opened + item.opened) * 100) / 100).toFixed(2));
            grouped[index].total = Number((Math.round((grouped[index].total + item.total) * 100) / 100).toFixed(2));
        } else {
            grouped.push(item);
        }
    });
    return grouped;
};

export const getSoldBySourceYearly = (state) => {
    const measurement = getDashboardMeasurement(state);
    const yearlySourceStats = _.get(state[NAME], 'yearlySourceStats.reportBySourceAndYear', []);

    const result = [];

    yearlySourceStats.forEach(item => {
        const index = result.findIndex(el => el.id === item.source);
        if (index >= 0) {
            if (measurement.value === 'ordersCount') {
                result[index].data.push(
                    {
                        x: item.year,
                        y: Number((item.received).toFixed(2))
                    });
            } else {
                result[index].data.push(
                    {
                        x: item.year,
                        y: Number((item.totalCharge).toFixed(2))

                    });
            }
        } else {
            if (measurement.value === 'ordersCount') {
                result.push({
                    id: item.source,
                    data: [
                        {
                            x: item.year,
                            y: Number((item.received).toFixed(2))
                        }
                    ]
                });
            } else {
                result.push({
                    id: item.source,
                    data: [
                        {
                            x: item.year,
                            y: Number((item.totalCharge).toFixed(2))
                        }
                    ]
                });
            }
        }
    });
    return result;
};


export const getSoldBySourceMonthly = (state) => {
    const measurement = getDashboardMeasurement(state);
    const monthlySourceStats = _.get(state[NAME], 'yearlySourceStats.reportBySourceYearAndMonth', []);

    const result = [];

    monthlySourceStats.forEach(item => {
        const index = result.findIndex(el => el.id === item.source);
        if (index >= 0) {
            if (measurement.value === 'ordersCount') {
                result[index].data.push(
                    {
                        x: moment.months(item.month - 1),
                        y: item.received
                    });
            } else {
                result[index].data.push(
                    {
                        x: moment.months(item.month - 1),
                        y: item.totalCharge

                    });
            }
        } else {
            if (measurement.value === 'ordersCount') {
                result.push({
                    id: item.source,
                    data: [
                        {
                            x: moment.months(item.month - 1),
                            y: item.received
                        }
                    ]
                });
            } else {
                result.push({
                    id: item.source,
                    data: [
                        {
                            x: moment.months(item.month - 1),
                            y: item.totalCharge
                        }
                    ]
                });
            }
        }
    });
    return result;
};

export const getDailySourceStatsLoading = (state) => _.get(state[NAME], 'dailySourceStatsLoading');
export const getDashboardMeasurement = (state) => _.get(state[NAME], 'dashboardMeasurement', 'Please select');

export const getSelectedSource = state => state[NAME].selectedSource;
export const getTrial = state => state[NAME].trialCreated;